<template>
    <div>
        <h1>Transactions</h1>


        <DataTable :columns="columns" id="tableuserstrans"
                   :ajax="url"
                   class="display" :options="tableOptions"
                   width="100%" @row-click="handleRowClick">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Narration</th>
                    <th>Method</th>
                    <th>Status</th>
                    <th>Action</th>
                    <th>Actione</th>

                </tr>
            </thead>
            <tfoot>
                <tr>
                    <th>ID</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Narration</th>
                    <th>Method</th>
                    <th>Status</th>
                    <th>Action</th>
                    <th>Actione</th>
                </tr>
            </tfoot>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3';
    import DataTablesCore from 'datatables.net';
    import Buttons from 'datatables.net-buttons';
    import $ from 'jquery';

    DataTable.use(DataTablesCore);
    DataTable.use(Buttons);

    // Load DataTables core
  //  DataTablesCore(window, $);

    export default {
        components: {
            DataTable,
        },
        data() {
            return {
                url: 'https://posetbet.com/api/v3/transactions',
                columns: [

                    { data: 'tid' },
                    { data: 'date' },
                    { data: 'amount' },
                    { data: 'narration' },
                    { data: 'method' },
                    { data: 'status' },
                    { data: 'message' },
                    { data: 'wallet' },

                ],

                tableOptions: {
                    order: [
                        [1, 'desc']
                    ]
                }

            };

        }
        ,
        methods: {
            handleCellClick(userId) {
                // Handle the cell click event using Vue Router's push method
                this.$router.push({ path: `/user/${userId}` });
            },
        },
        mounted() {
            const self = this;

            // Add click event listener to the table for the 'cell-click' class
            this.$nextTick(() => {
                console.log('called')
                const table = $('#tableusers'); // Replace with your actual DataTable element selector
                table.on('click', '.cell-click', function () {
                    const userId = $(this).data('id');
                    self.handleCellClick(userId);
                });
            });
        },
    }
</script>

<style>
    @import 'datatables.net-dt';
</style>

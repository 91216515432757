<template>
    <div>
        <h1>Users</h1>
       

        <DataTable :columns="columns" id="tableusers"
                   ajax="https://posetbet.com/api/v3/users"
                   class="display"
                   width="100%"   @row-click="handleRowClick">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Username</th>
                    <th>Email </th>
                    <th>Country</th>
                    <th>Currency</th>
                    <th>Balance</th>
                    <th>ID </th>
                    <th>Address</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tfoot>
                <tr> <th>ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Username</th>
                    <th>Email </th>
                    <th>Country</th>
                    <th>Currency</th>
                    <th>Balance</th>
                    <th>ID </th>
                    <th>Address</th>
                <th>Action</th>
                </tr>
            </tfoot>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from 'datatables.net-vue3';
    import DataTablesCore from 'datatables.net';
    import Buttons from 'datatables.net-buttons';
    import $ from 'jquery';
  
    DataTable.use(DataTablesCore);
    DataTable.use(Buttons);

    // Load DataTables core
  //  DataTablesCore(window, $);

    export default {
        components: {
            DataTable,
        },
        data() {
            return {
                columns: [
                    {
                        data: 'ID',
                        render: function (data, type) {
                            if (type === 'display') {
                                // Create a clickable cell using the renderer
                                return `<a href="javascript:void(0);"  class="cell-click" data-id="${data}">${data}</a>`;
                            }
                            return data;
                        }
                        },
                    { data: 'FirstName' },
                    { data: 'LastName' },
                    { data: 'username' },
                    { data: 'Email' },
                    { data: 'Country' },
                    { data: 'currency' },
                    { data: 'balance' },
                    { data: 'id_verified' },
                    { data: 'address_verified' },
                    { data: 'status' }
                ]

            };

        }
        ,
        methods: {
            handleCellClick(userId) {
                // Handle the cell click event using Vue Router's push method
                this.$router.push({ path: `/user/${userId}` });
            },
        },
        mounted() {
            const self = this;
           
            // Add click event listener to the table for the 'cell-click' class
            this.$nextTick(() => {
                console.log('called')
                const table = $('#tableusers'); // Replace with your actual DataTable element selector
                table.on('click', '.cell-click', function () {
                    const userId = $(this).data('id');
                    self.handleCellClick(userId);
                });
            });
        },
    }
</script>

<style>
    @import 'datatables.net-dt';
</style>
